<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({})
export default class NavBar extends Vue {}
</script>

<template>
  <nav class="sticky top-0">
    <div class="container mx-auto bg-white nav">
      <div class="flex items-center justify-between px-6 md:mx-6 h-16">
        <!-- Logo -->
        <div>
          <router-link to="/">
            <img
              src="../assets/logo.jpg"
              alt=""
              class="w-16 px-2 rounded-md"
            />
          </router-link>
        </div>
        <!-- Menu -->
        <div class="flex space-x-6 items-center">
          <router-link class="text-white font-semibold" to="/"
            >Home</router-link
          >
          <router-link class="text-white font-semibold" to="/services"
            >Services</router-link
          >
          <router-link class="text-white font-semibold" to="/booking"
            >Booking</router-link
          >
        </div>
      </div>
    </div>
  </nav>
</template>

<style scoped>
.nav {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 0px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
</style>
