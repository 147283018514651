<!-- eslint-disable max-len -->
<!-- eslint-disable max-len -->
<!-- eslint-disable max-len -->
<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({})
export default class MyFooter extends Vue {}
</script>

<template>
  <footer class="container mx-auto relative">
    <div class="flex flex-col">
      <img src="../assets/footer.jpeg" alt="" class="min-w-full md:hidden" />
      <img
        src="../assets/footer-ls.jpeg"
        alt=""
        class="min-w-full hidden md:block"
      />
      <div class="absolute top-6 px-6 w-full">
        <div class="flex flex-row items-center justify-between">
          <div
            class="text-2xl md:text-4xl text-white font-bold font-cormorant-garamond"
          >
            Perfect nails Moore
          </div>
          <div class="flex space-x-6">
            <a href="https://www.facebook.com/perfectnails.moore">
              <img src="../assets/fb.png" class="h-6 md:h-10 inverted" alt="" />
            </a>

<!--            <a href="">-->
<!--              <img-->
<!--                src="../assets/tweeter.png"-->
<!--                class="h-6 md:h-10 inverted"-->
<!--                alt=""-->
<!--              />-->
<!--            </a>-->
<!--            <a href="">-->
<!--              <img-->
<!--                src="../assets/youtube.png"-->
<!--                class="h-6 md:h-10 inverted"-->
<!--                alt=""-->
<!--              />-->
<!--            </a>-->
          </div>
        </div>
      </div>

      <div
        class="absolute top-16 md:top-22 px-6 text-white text-sm md:text-lg w-screen flex flex-col"
      >
        <!--      <div>Mon-Sat: 9:30 AM - 7:00 PM  &#8226;  Sun: Close</div>-->
        <!--      <div>1605 S I-35 Service Rd, Moore, OK 73160</div>-->
        <!--      <div>(405) 790-1195</div>-->
        <div>
          Monday - Saturday: 9:30 AM - 7:00 PM &#8226; Sunday: 12:00 PM - 5:00 PM &#8226;
          1605 S I-35 Service Rd, Moore, OK 73160 &#8226; (405) 790-1195
        </div>
      </div>

      <div
        class="absolute flex flex-col bottom-6 justify-between w-screen px-6 text-sm"
      >
        <div class="flex flex-row items-end">
          <a href="https://3amlab.dev" class="flex flex-row items-center">
            <img src="../assets/3am_logo.png" class="h-6 md:h-10 mr-4" alt="" />
          </a>

          <div class="flex flex-col">
            <!--          <span class="text-white text-sm">Powered by</span><span class="text-white underline text-sm">3AM Lab</span>-->
            <div class="text-white text-sm md:text-lg">
              Copyright &copy; 2024, All Rights Reserved
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped>
/* Apply the inverted effect using the filter property */
.inverted {
  filter: invert(100%);
}
</style>
