import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
  RouteRecordRaw,
} from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/AboutView.vue"),
  },
  {
    path: "/services",
    name: "services",
    component: () => import("../views/ServicesView.vue"),
  },
  {
    path: "/menu",
    name: "menu",
    component: () => import("../views/ServicesView.vue"),
  },
  {
    path: "/booking",
    name: "booking",
    // redirect:
    //   "http://3amlab.dev/#/rocket-tickets/store/morgan-oasis-nails/appointment/booking",
    component: () => import("../views/BookingView.vue"),
  },
];

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  // history: createWebHistory(),
  history: createWebHashHistory(),
  routes,
});

// const router = new VueRouter({
//   mode: "history",
//   base: process.env.BASE_URL,
//   routes,
// });

export default router;

// const http = require("http");
// const fs = require("fs");
// const httpPort = 80;
//
// http
//   .createServer((req, res) => {
//     fs.readFile("index.html", "utf-8", (err, content) => {
//       if (err) {
//         console.log('We cannot open "index.html" file.');
//       }
//
//       res.writeHead(200, {
//         "Content-Type": "text/html; charset=utf-8",
//       });
//
//       res.end(content);
//     });
//   })
//   .listen(httpPort, () => {
//     console.log("Server listening on: http://localhost:%s", httpPort);
//   });
