<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({})
export default class NavBar extends Vue {}
</script>

<template>
  <nav class="sticky top-0">
    <div
      class="container mx-auto bg-[url('../assets/colors.jpg')] bg-cover bg-center bg-no-repeat h-[32rem] text-white"
    >
      <div class="flex items-center justify-between px-6 md:mx-6 h-16">
        <!-- Logo -->
        <div>
          <router-link to="/">
            <!-- <img
              src="../assets/logo.png"
              alt=""
              class="w-20 px-2 bg-white rounded-md"
            /> -->
            <!--            <div class="text-2xl px-1 bg-red-500 font-giaza">Oasis</div>-->
          </router-link>
        </div>
        <!-- Menu -->
        <div class="flex space-x-6 items-center">
          <router-link class="" to="/">Home</router-link>
          <router-link class="" to="/menu">Menu</router-link>
          <router-link class="" to="/about">About</router-link>
        </div>
      </div>
      <hr class="h-[0.1rem] border-t-0 bg-white" />

      <div class="flex flex-col mx-6 mt-6 items-center space-y-72">
        <p class="text-3xl text-white text-center">
          There's nothing a fresh manicure can't fix
        </p>
        <!--        <p class="text-white">(405) 790-1195</p>-->
        <!--        <p class="text-white">-->
        <!--          1719 S Morgan Rd, Oklahoma City <br />-->
        <!--          OK 73128-->
        <!--        </p>-->
        <p
          class="border border-solid border-white py-1 px-3 text-white text-lg"
        >
          <a href="tel:(405) 790-1195"> Book Now </a>
        </p>
      </div>
      <!-- Mobile Menu -->
      <!--      <div class="md:hidden">-->
      <!--        <div-->
      <!--          id="menu"-->
      <!--          class="absolute flex-col items-center hidden self-end py-8 mt-10 space-y-6 sm:w-auto sm:self-center left-6 right-6"-->
      <!--        >-->
      <!--          <a href="#" class="hover:text-red-400">Home</a>-->
      <!--          <a href="#" class="hover:text-red-400">About</a>-->
      <!--          <a href="#" class="hover:text-red-400">Contact</a>-->
      <!--          <a href="#" class="hover:text-red-400">Login</a>-->
      <!--          <a href="#" class="hover:text-red-400">Register</a>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </nav>
</template>

<style scoped>
@font-face {
  font-family: "giaza";
  src: url("../assets/Giaza Stencil.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
</style>
